import { ActionContext } from 'vuex';
import { IRootState } from '@/store';
import axios from '@/plugins/axios';
import {
    authMutations,
    IAuthState,
    IDataSignIn,
    IDataSignUp,
    URL_SIGN_IN,
    URL_SIGN_UP,
    URL_AUTHENTIC,
    KEY_TOKEN_USER,
    HEADER_TOKEN_USER,
    BRAINTREE_TOKEN,
    LIST_TOTAL_ATTRIBUTE,
    IS_LOADING_ATTRIBUTE,
    LOAD_ATTRIBUTE_DONE,
    REDIRECT_PATH,
} from './auth.constant';

const authAction = {
    setShowSignIn(
        context: ActionContext<IAuthState, IRootState>,
        showLoading: boolean
    ) {
        context.commit(authMutations.SET_SHOW_SIGN_IN, showLoading);
    },

    async signIn(
        context: ActionContext<IAuthState, IRootState>,
        dataSignIn: IDataSignIn
    ) {
        const siginInResponse = await axios.post(URL_SIGN_IN, {
            ...dataSignIn,
        });
        return siginInResponse;
    },
    async signUp(
        context: ActionContext<IAuthState, IRootState>,
        dataSignUp: IDataSignUp
    ) {
        const siginUpResponse = await axios.post(URL_SIGN_UP, {
            ...dataSignUp,
        });
        return siginUpResponse;
    },

    async setTokenUser(
        context: ActionContext<IAuthState, IRootState>,
        tokenUser: string
    ) {
        await localStorage.setItem(KEY_TOKEN_USER, tokenUser);
    },
    async setBraitreeToken(
        context: ActionContext<IAuthState, IRootState>,
        tokenUser: string
    ) {
        await localStorage.setItem(BRAINTREE_TOKEN, tokenUser);
    },

    async getTokenUser() {
        const tokenUser = await localStorage.getItem(KEY_TOKEN_USER);
        return tokenUser;
    },

    async authentic(
        context: ActionContext<IAuthState, IRootState>,
    ) {
        const tokenUser = await context.dispatch('getTokenUser');
        const authenticResponse = await axios.get(URL_AUTHENTIC, {
            headers: {
                [HEADER_TOKEN_USER]: tokenUser,
            }
        });
        return authenticResponse;
    },

    async setDataLogin(
        context: ActionContext<IAuthState, IRootState>,
        dataLogin: any
    ) {
        context.commit(authMutations.SET_DATA_LOGIN, dataLogin)
    },

    async setAccountSelected(
        context: ActionContext<IAuthState, IRootState>,
        accountSelected: any
    ) {
        context.commit(authMutations.ACCOUNT_SELECTED, accountSelected)
    },
    async setAttributes(
        context: ActionContext<IAuthState, IRootState>,
        attributes: any
    ) {        
        await localStorage.setItem(LIST_TOTAL_ATTRIBUTE, attributes);
    },
    async setIsLoadingAttributes(
        context: ActionContext<IAuthState, IRootState>,
        isLoadingAttributes: any
    ) {        
        await localStorage.setItem(IS_LOADING_ATTRIBUTE, isLoadingAttributes);
    },
    async setLoadAttributesDone(
        context: ActionContext<IAuthState, IRootState>,
        isLoadAttributesDone: any
    ) {        
        await localStorage.setItem(LOAD_ATTRIBUTE_DONE, isLoadAttributesDone);
    },
    async setRedirectPath(
        context: ActionContext<IAuthState, IRootState>,
        redirectPath: any
    ) {        
        await localStorage.setItem(REDIRECT_PATH, redirectPath);
    },
    async getRedirectPath() {
        const Path = await localStorage.getItem(REDIRECT_PATH);
        return Path;
    },
}
export default authAction;
