
import { Vue } from 'vue-class-component'

export default class HomeLayout extends Vue {
  checkAuthenticDone = false
  async created() {
    document.title = `${this.$router.currentRoute.value.meta.title} - iOmniC`
    await this.checkAuthentic()
  }

  async checkAuthentic() {
    if (this.$router.currentRoute.value.name === '404') {
      this.checkAuthenticDone = true
      return
    }
    const tokenUser = await this.$store.dispatch('auth/getTokenUser')
    if (!tokenUser) {
      this.checkAuthenticDone = true
      return
    }
    const authenticResponse = await this.$store.dispatch('auth/authentic')
    if (!authenticResponse.email) {
      this.checkAuthenticDone = true
      return
    }
    await this.$router.push({ name: 'DashboardPage' })
  }
}
