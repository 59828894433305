import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { authRouters } from '@/modules/auth/router/auth.router'
import { notFoundRouter } from '@/modules/404/router/notfound.router'
import { productRouter } from '@/modules/product/router/product.router'
import { dashboardRouter } from '@/modules/dashboard/router/dashboard.router'
import { orderRouter } from '@/modules/order/router/order.router'
import { syncErrorsRouter } from '@/modules/sync-error/router/sync-errors.router'
import { catalogRouter } from '@/modules/catalog/router/catalog.router'
import { settingRouter } from '@/modules/setting/router/setting.router'
import { AccountInfoRouter } from '@/modules/account-info/router/account-info.router'
import { invoiceRouter } from '@/modules/invoice/router/invoice.router'
import { userRouter } from '@/modules/user/router/user.router'
import { notificationRouter } from '@/modules/notification/router/notification.router'
import { accountManagerRouter } from '@/modules/accountManager/router/accountManager.router'
import { accountRouter } from '@/modules/account/router/account.router'
import { getStartedRouter } from '@/modules/get-started/router/get-started.router'
import { applicationKeyRouter } from '@/modules/applicationKey/router/applicationKey.router'
import { accountBillingRouter } from '@/modules/accountBilling/router/accountBilling.router'
import { scriptCustomRouter } from '@/modules/script-custom/router/script-custom.router'
import { platformRouter } from '@/modules/platform/router/platform.router'
import { connectionRouter } from '@/modules/connection/router/connection.router'
import { packageRouter } from '@/modules/package/router/package.router'
import { cronManagerRouter } from '@/modules/cron-manager/router/cron-manager.router'
import { RoleManagementRouter } from '@/modules/role-management/router/role-management.router'
import { ComfirmSubscriptionRouter } from '@/modules/comfirm-subscription/router/comfirm-subscription.router'
import { BraintreeAPIRouter } from '@/modules/braintree/router/braintree-api'
import { SubscriptionRouter } from '@/modules/subscription/router/subscription'
import { TemplateRouter } from '@/modules/template/router/template'
import { store } from '@/store' // path to store file

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/layout/HomeLayout.vue')
  },
  { path: '/:catchAll(.*)', redirect: '/404' },
  ...authRouters,
  notFoundRouter,
  dashboardRouter,
  productRouter,
  orderRouter,
  syncErrorsRouter,
  catalogRouter,
  settingRouter,
  AccountInfoRouter,
  invoiceRouter,
  userRouter,
  notificationRouter,
  accountManagerRouter,
  accountRouter,
  applicationKeyRouter,
  accountBillingRouter,
  getStartedRouter,
  scriptCustomRouter,
  platformRouter,
  connectionRouter,
  packageRouter,
  cronManagerRouter,
  RoleManagementRouter,
  ComfirmSubscriptionRouter,
  BraintreeAPIRouter,
  SubscriptionRouter,
  TemplateRouter,
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: AboutComponent
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
let typeAccount = 0
let authenticResponse: any

async function getTypeAccount() {
  authenticResponse = await store.dispatch('auth/authentic')
  if (authenticResponse === undefined) {
    return false
  } else {
    typeAccount = authenticResponse.type?.toString()
  }
}

// router.beforeResolve(to => {
//   if (to.meta.isSuperAdmin && typeAccount != 1) return false;
// })
router.beforeEach(async (to, from, next) => {
  await getTypeAccount()
  //  await store.dispatch("auth/setRedirectPath", to.fullPath);
  //  sessionStorage.setItem('redirectPath', to.path);
  const token = localStorage.getItem('tokenUser')
  const isShowUserSidebar = localStorage.getItem('isShowUserSidebar')

  // const a = to.fullPath;
  // if (localStorage.getItem("tokenUser") == null) {
  //   sessionStorage.setItem('redirectPath', a);
  // }

  // if ((to.meta.isSuperAdmin && typeAccount != 1) || (isShowUserSidebar != 'true' && (to.path == '/users' || to.path == '/account-detail'))){
  // if ((to.meta.isSuperAdmin && typeAccount != 1) || (isShowUserSidebar != 'true' && ( to.path == '/account-detail'))){
  if (to.meta.isSuperAdmin && typeAccount != 1) {
    next({
      path: '404',
      replace: true
    })
  } else {
    if (
      token == null &&
      to.path === '/comfirm-subscription' &&
      Object.keys(to.query)[0] == 'accountId'
    ) {
      localStorage.setItem('redirectPath', to.fullPath)
      next({
        path: '/'
        // Redirect to original path if specified
      })
    } else next()
  }
})
export default router
