export enum authMutations {
    SET_SHOW_SIGN_IN = 'SET_SHOW_SIGN_IN',
    SET_DATA_LOGIN = 'SET_DATA_LOGIN',
    ACCOUNT_SELECTED = 'ACCOUNT_SELECTED'
}

export const URL_SIGN_IN = 'sign-in';
export const URL_SIGN_UP = 'sign-up';
export const URL_AUTHENTIC = 'authentic';

export const KEY_TOKEN_USER = 'tokenUser';
export const HEADER_TOKEN_USER = 'token-user';
export const BRAINTREE_TOKEN = 'braintree-token';
export const HEADER_ACCOUNT = 'account';
export const LIST_TOTAL_ATTRIBUTE = 'list-total-attribute';
export const IS_LOADING_ATTRIBUTE = 'is-loading-attribute';
export const LOAD_ATTRIBUTE_DONE = 'load-attribute-done';
export const REDIRECT_PATH = 'redirect-path';

export interface IDataSignIn {
    email: string,
    password: string,
}
export interface IDataSignUp {
    nameAccount: string,
    email: string,
    password: string,
    passwordConfirm: string
}

export interface IAuthState {
    isShowSignIn: boolean,
    dataLogin: any,
    accountSelected: any,
}
