import { IAuthState, authMutations } from './auth.constant';
const mutations = {
    [authMutations.SET_SHOW_SIGN_IN](state: IAuthState, showLoading: boolean) {
		state.isShowSignIn = showLoading;
	},
	[authMutations.SET_DATA_LOGIN](state: IAuthState, dataLogin: any) {
		state.dataLogin = dataLogin;
	},
	[authMutations.ACCOUNT_SELECTED](state: IAuthState, accountSelected: any) {
		state.accountSelected = accountSelected;
	},
};

export default mutations;
