import { createApp } from 'vue'
import { store } from '@/store'
import router from './router'
import { i18n } from './lang/index'
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import 'element-plus/lib/theme-chalk/index.css'
import './assets/css/index.css'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

import App from './App.vue'

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(ElementPlus)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
