import CMSLayout from '@/layout/CMSLayout.vue'

export const dashboardRouter = {
  path: '/',
  component: CMSLayout,
  children: [
    {
      path: '/dashboard',
      name: 'DashboardPage',
      // lazy load
      component: () => import('../page/DashboardPage.vue'),
      meta: {
        onlyWhenLoggedOut: true,
        public: true,
        title: 'Dashboard'
      }
    }
  ]
}
