
import { Vue } from "vue-class-component";
import { ElMessageBox } from "element-plus";
import axios from "@/plugins/axios";
import { ElNotification } from "element-plus";

interface Res {
  list: [];
  total: number;
}
interface Response {
  status: boolean;
  has_confirmation_url: boolean;
  message: string;
  confirmation_url: string;
}

export default class AppNavbar extends Vue {
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  listCron = [
    {
      user: {
        disableByAdmin: false,
      },
    },
  ];
  accounts = [];
  nameAccountSelected = "";
  idAccountSelected = "";
  dataDate = "All products";
  disabledDate = (time: Date) => {
    return time.getTime() > Date.now();
  };
  net: any;
  accountSelected: any;
  listExpiryDate: any = [];
  listExpiryShopify: any = [];
  isCollapse = false;
  isSuperAdmin = false;
  showMassSync = false;
  icon = "el-icon-caret-left";
  targetConnection = "";
  listConnectSPTarget: any = [];
  listConnectChangeBilling: any = [];
  listChooseDate = [
    "All products",
    "From yesterday",
    "From last week",
    "From last month",
    "From last year",
  ];
  hubIsShopify = false;
  accessToken: any;
  shopify_shopName: any;
  newConnect: any;
  async created() {
    this.accounts = await this.$store.state.auth.dataLogin.account;
    this.isSuperAdmin = await this.$store.state.auth.dataLogin.type;
    this.targetConnection = await this.$store.state.targetConnection;
    this.newConnect = this.$route.query.newConnect;
     this.accountSelected = await this.$store.state.auth.accountSelected;
    this.nameAccountSelected = this.accountSelected.name
   // this.setAccountName();

    setTimeout(async () => {
      await this.account();
      await this.getListCron();
    }, 4000);
  }

  async selecteAccount(id: string) {
    await this.$store.dispatch("setLoading", true, { root: true });
    await localStorage.setItem("account", id);
  //  this.setAccountName();
    location.reload();
    await this.$store.dispatch("setLoading", false, { root: true });
  }

  setAccountName() {
    if (this.accounts) {
      this.accounts.forEach((acc) => {
        if (acc["_id"] === localStorage.getItem("account")) {
          this.idAccountSelected = acc["_id"];
          return (this.nameAccountSelected = acc["name"]);
        }
      });
    }
  }
  async getListCron(page = 0) {
    let res: Res = {
      list: [],
      total: 0,
    };
    res = await axios.get("user/setting-script", {
      headers: this.headers,
      params: {
        account: localStorage.getItem("account"),
        page: page,
      },
    });
    if (res) {
      this.listCron = res.list;
      if (this.listCron[0]?.user?.disableByAdmin) {
        ElMessageBox.alert(
          "Your account has been disabled by Admin. Crons will stop running. Please contact customer support via support@wiserobot.com for the further assistance.",
          "Notification",
          {
            confirmButtonText: "OK",
            type: "warning",
            center: true,
          }
        );
      }
    }
  }
  async account() {
    await axios
      .post("user/account/detail",{}, {
        headers: this.headers,
      })
      .then((response) => {
        this.net = response;
        this.net.data.connectionData.forEach((element: any) => {
          if (element.isChangeBilling && element.charge_id) {
            this.listConnectChangeBilling.push(element);
          }
          element.connections.forEach((connection: any) => {
            if (connection.type == "shopify" && connection.from) {
              this.hubIsShopify = true;
              this.accessToken = connection.connection.tokenObject.accessToken || null;
              this.shopify_shopName = connection.connection.shopify_shopName;
            }
          });
          if (!element.isActive && !element.deletedDate) {
            this.listExpiryDate.push(element);
          }
          if (
            element.isActive == false &&
            !element.deletedDate &&
            element.isActiveShopify == false
          ) {
            this.listExpiryShopify.push(element);
          }
        });

      })
      .catch((error) => {
        console.log(error);
      });

    if (this.listExpiryShopify.length > 0) {
      this.listExpiryShopify.forEach((a: any) => {
        a.connections.forEach((connection: any) => {
          if (connection.type == "shopify" && !connection.from) {
            this.listConnectSPTarget.push({
              accessToken: connection.connection.tokenObject.accessToken || null,
              shopify_shopName: connection.connection.shopify_shopName,
              package_id: a._id,
              package_price: a.price,
              no_trial_days: true,
            });
          }
        });
      });
      if (this.listConnectSPTarget.length > 0) {
        this.listConnectSPTarget.forEach(async (element: any) => {
          await this.sleep();
          ElMessageBox.alert(
            `Unfortunately, we couldn't verify your ${element.shopify_shopName} Shopify subscription or your Shopify subscription has been expired.  Therefore, most of the features of iOmniC have been disabled,  please contact our team: support@wiserobot.com for further support or click the below button to re-authorize your Shopify subscription.`,
            "Notification",
            {
              confirmButtonText: "Confirm Subscription",
              type: "warning",
              center: true,
            }
          ).then(async () => {
            await this.$store.dispatch("setLoading", true, { root: true });
            let res: Response = await axios.get(
              "user/connect/shopify-comfirm-subscription",
              {
                headers: this.headers,
                params: JSON.stringify(element),
              }
            );
            if (res.status && res.has_confirmation_url) {
              window.location.href = res.confirmation_url;
            } else {
              this.$message.warning(res.message);
              await this.$store.dispatch("setLoading", false, {
                root: true,
              });
            }
          });
        });
      }
      if (this.hubIsShopify && this.newConnect !== "true") {
        let params = {
          accessToken: this.accessToken,
          shopify_shopName: this.shopify_shopName,
          no_trial_days: true,
        };

        ElMessageBox.alert(
          `Unfortunately, we couldn't verify your Shopify subscription or your Shopify subscription has been expired.  Therefore, most of the features of iOmniC have been disabled,  please contact our team: support@wiserobot.com for further support or click the below button to re-authorize your Shopify subscription.`,
          "Notification",
          {
            confirmButtonText: "Confirm Subscription",
            type: "warning",
            center: true,
          }
        ).then(async () => {
          await this.$store.dispatch("setLoading", true, { root: true });
          let res: Response = await axios.get(
            "user/connect/shopify-comfirm-subscription",
            {
              headers: this.headers,
              params: JSON.stringify(params),
            }
          );
          if (res.status && res.has_confirmation_url) {
            window.location.href = res.confirmation_url;
          } else {
            this.$message.warning(res.message);
            await this.$store.dispatch("setLoading", false, { root: true });
          }
        });
      }
    }

    if (this.listConnectChangeBilling.length > 0 && this.hubIsShopify) {
      let params = {
        accessToken: this.accessToken,
        shopify_shopName: this.shopify_shopName,
        no_trial_days: true,
      };
      ElMessageBox.alert(
        `Your connection price has been updated, and your Shopify subscription need to be adjusted. To complete your subscription click the below button to update your Shopify subscription. Please contact our team: support@wiserobot.com for further support`,
        "Your Shopify subscription has been updated",
        {
          confirmButtonText: "Update Subscription",
          type: "warning",
          center: true,
          showClose: false,
        }
      ).then(async () => {
        await this.$store.dispatch("setLoading", true, { root: true });
        let res: Response = await axios.get(
          "user/connect/shopify-comfirm-subscription",
          {
            headers: this.headers,
            params: JSON.stringify(params),
          }
        );
        if (res.status && res.has_confirmation_url) {
          window.location.href = res.confirmation_url;
        } else {
          this.$message.warning(res.message);
          await this.$store.dispatch("setLoading", false, { root: true });
        }
      });
    }
    if (this.listConnectChangeBilling.length > 0 && !this.hubIsShopify) {
      this.listConnectChangeBilling.forEach(async (element: any) => {
        if (element.charge_id) {
          element.connections.forEach(async (connect: any) => {
            if (connect.type === "shopify") {
              let params = {
                accessToken: connect.connection.tokenObject.accessToken || null,
                shopify_shopName: connect.connection.shopify_shopName,
                package_id: element._id,
                package_price: element.price,
                no_trial_days: true,
              };
              await this.sleep();
              ElMessageBox.alert(
                `Your connection price has been updated, and your ${connect.connection.shopify_shopName} subscription need to be adjusted. To complete your subscription click the below button to update your Shopify subscription. Please contact our team: support@wiserobot.com for further support`,
                "Your Shopify subscription has been updated",
                {
                  confirmButtonText: "Update Subscription",
                  type: "warning",
                  center: true,
                  showClose: false,
                }
              ).then(async () => {
                await this.$store.dispatch("setLoading", true, { root: true });
                let res: Response = await axios.get(
                  "user/connect/shopify-comfirm-subscription",
                  {
                    headers: this.headers,
                    params: JSON.stringify(params),
                  }
                );
                if (res.status && res.has_confirmation_url) {
                  window.location.href = res.confirmation_url;
                } else {
                  this.$message.warning(res.message);
                  await this.$store.dispatch("setLoading", false, {
                    root: true,
                  });
                }
              });
            }
          });
        }
      });
    }

        if (
         (this.listExpiryDate.length > 0 && this.listConnectSPTarget.length == 0 && this.hubIsShopify == false) 
        ) {
          ElMessageBox.alert(
            "We sincerely regret to inform you that your account has been suspended. Unfortunately we could not bill you again maybe your credit card has expired. You can reactivate your account by signing in to update your payment information, and the subscription charge will be processed immediately per next due. Should you need any further assistance, please contact our team: support@wiserobot.com",
            "Notification",
            {
              confirmButtonText: "Go Payment",
              type: "warning",
              center: true,
            }
          ).then(() => {
            this.$router.push({ name: "AccountPaymentPage" });
          });
        }
  }
  sleep() {
    return new Promise((resolve) => setTimeout(resolve, 1000));
  }
  async handleSignOut() {
    await ElMessageBox.confirm("Do you want to logout?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    }).then(async() => {
      localStorage.removeItem("tokenUser");
      localStorage.removeItem("account");
      localStorage.removeItem("isShowChangeAccount");
             localStorage.setItem("redirectPath", "");

      this.$router.push({ name: "LoginPage" });
      location.reload();
    });
  }
  showMassSyncBtn() {
    this.showMassSync = true;
  }
  handleSelectDate() {
    var today = new Date();
    var obj: any = {
      "From yesterday": new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1
      ),
      "From last week": new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
      ),
      "From last month": new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      ),
      "From last year": new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      ),
    };

    this.listChooseDate.forEach((element) => {
      if (element == this.dataDate) {
        this.dataDate = obj[element];
      }
    });
    if (this.dataDate == undefined) {
      this.dataDate = "All products";
    }
  }
  cancelMassSync() {
    this.showMassSync = false;
    this.dataDate = "All products";
  }
  async handleMassSync() {
    await this.$store.dispatch("setLoading", true, { root: true });
    let data: any = this.dataDate;
    if (data === "All products") {
      data = undefined;
    } else data = new Date(data).toISOString();
    await axios
      .post(
        "user/connect/product-sync-all",
        {
          id: this.$store.state["targetConnection"],
          data: data,
        },
        {
          headers: this.headers,
        }
      )
      .then((response) => {
        if (response.status) {
          this.showMassSync = false;
          this.dataDate = "All products";
          ElMessageBox.alert(
            "Product Mass Sync would be started in the next cron run.",
            "Success",
            {
              type: "success",
            }
          );
        }
      })
      .catch((error) => {
        ElMessageBox.alert(error, "Error", {
          type: "error",
        });
        console.log(error);
      });
    await this.$store.dispatch("setLoading", false, { root: true });
  }
}
